.MainCTA {
  height: 100%;
  position: relative;
  display: grid;
  &__bg-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    filter: brightness(0.65);
  }
  &__logo {
    z-index: 1;
    width: 90vw;
    max-width: 900px;
    padding: 1rem;
    height: auto;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    justify-self: center;
  }
}

.Logo {
  * {
    fill: white;
    stroke: white;
  }
}

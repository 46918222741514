.Member {
  color: grey;
  text-align: center;
  img {
    max-width: 150px;
    max-height: 300px;
    object-fit: cover;
  }
  span {
    font-weight: bold;
    color: black;
  }
}

.Section {
  height: 100%;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  background: white;
  position: relative;
  border-radius: 0.5rem;
  z-index: 1;
}

:root {
  --pixels-1:                                                                0.0625rem;
  --pixels-2:                                                                0.125rem;
  --pixels-3:                                                                0.1875rem;
  --pixels-4:                                                                0.25rem;
  --pixels-5:                                                                0.3125rem;
  --pixels-6:                                                                0.375rem;
  --pixels-7:                                                                0.4375rem;
  --pixels-8:                                                                0.5rem;
  
  --text-size-xs:                                                            0.75rem;
  --text-size-s:                                                             0.875rem;
  --text-size-m:                                                             1rem;
  --text-size-l:                                                             1.125rem;
  --text-size-xl:                                                            1.25rem;
  --text-size-xxl:                                                           1.375rem;
  --text-size-xxxl:                                                          1.625rem;
  
  --border-radius-xs:                                                        0.125rem;
  --border-radius-s:                                                         0.25rem;
  --border-radius-m:                                                         0.5rem;
  --border-radius-l:                                                         0.75rem;
  --border-radius-xl:                                                        1rem;
  
  --text-weight-bold:                                                        700;
  --text-weight-normal:                                                      600;
  --text-weight-light:                                                       300;
  
  --transition-150:                                                          150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  --transition-300:                                                          300ms cubic-bezier(0.25, 0.8, 0.25, 1);
  
  --border-width-thin:                                                       0.0625rem;
  --border-width-default:                                                    0.125rem;
  
  --focus-ring:                                                              0 0 0 3px rgba(0, 81, 255, 0.5);
  --focus-ring-inset:                                                        0 0 0 3px inset rgba(0, 81, 255, 0.5);
  
  --box-shadow-100:                                                          0 0    3px  rgba(0, 0, 0, 0.02),     0 1px  3px  rgba(0, 0, 0, 0.04),   0 1px  2px  rgba(0, 0, 0, 0.08);
  --box-shadow-100-dark:                                                     0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-100-focus:                0 0 0 3px rgba(0, 81, 255, 0.5),    0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-200:                                                          0 0    6px  rgba(0, 0, 0, 0.02665),  0 3px  6px  rgba(0, 0, 0, 0.0533), 0 3px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-200-dark:                                                     0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-200-focus:                0 0 0 3px rgba(0, 81, 255, 0.5),    0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300:                                                          0 0    20px rgba(0, 0, 0, 0.03165),  0 10px 20px rgba(0, 0, 0, 0.0633), 0 6px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-300-dark:                                                     0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300-focus:                0 0 0 3px rgba(0, 81, 255, 0.5),    0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-400:                                                          0 0    28px rgba(0, 0, 0, 0.04165),  0 14px 28px rgba(0, 0, 0, 0.0833), 0 10px 10px rgba(0, 0, 0, 0.0733);
  --box-shadow-400-dark:                                                     0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-400-focus:                0 0 0 3px rgba(0, 81, 255, 0.5),    0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-500:                                                          0 0    38px rgba(0, 0, 0, 0.05),     0 19px 38px rgba(0, 0, 0, 0.1),    0 15px 12px rgba(0, 0, 0, 0.0733);
  --box-shadow-500-dark:                                                     0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  --box-shadow-500-focus:                0 0 0 3px rgba(0, 81, 255, 0.5),    0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);  
}
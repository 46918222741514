:root {
  --app-height: 100%;
}

.App {
  height: 100vh;
  height: var(--app-height);
  &__sections {
    max-width: 900px;
    margin: 0 auto;
  }
}

.Footer {
  span {
    color: grey;
  }
  &__link {
    svg {
      border-radius: 0.125rem;
      width: 25px;
      height: 25px;
      * {
        fill: grey;
      }
    }
    &:hover {
      svg * {
        fill: black;
      }
    }
  }
}
